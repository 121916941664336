import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./HomePage.css";
import lady1 from "./tmimages/Lady-1.png";
import BannerImage from "./tmimages/Banner.jpg";
import logo from "./tmimages/gold-logo.png";
import sunimage from "./tmimages/sun-image1.png";
import sun from "./tmimages/sun.png";
import sign from "./tmimages/sign.png";
import bg1 from "./tmimages/bg1.png";
import yoga from "./tmimages/yoga.png";
import life2 from "./tmimages/life-2.png";
import couple from "./tmimages/couple.png";
import family from "./tmimages/family.png";
import marital from "./tmimages/marital.png";
import oct from "./tmimages/oct.png";
import communication from "./tmimages/communication.png";
import conflict from "./tmimages/conflict.png";
import emotional from "./tmimages/emotional.png";
import bg2 from "./tmimages/bg2.png";
import family2 from "./tmimages/family2.png";
import misunderstanding from "./tmimages/life-3.png";
import counselling1 from "./tmimages/1.png";
import counselling2 from "./tmimages/2.png";
import counselling3 from "./tmimages/3.png";
import counselling4 from "./tmimages/4.png";
import counselling5 from "./tmimages/5.png";
import counselling6 from "./tmimages/6.png";
import sun1 from "./tmimages/sun-1.png";
import time from "./tmimages/Time.png";
import sunvideo from "./tmimages/sun-but.png";
import { MdMail } from "react-icons/md";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaVideo } from "react-icons/fa";
import arrow from "./tmimages/arow.png";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TiSocialGooglePlus } from "react-icons/ti";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaPinterestP } from "react-icons/fa";
import { IoShareSocialSharp } from "react-icons/io5";
import { IoIosChatboxes } from "react-icons/io";
import { BiSolidDownArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

const HomePage = () => {
  const navi = useNavigate();
  const navToHome = useNavigate();
  const handlenavToConsent = () => {
    window.scrollTo(0, 0);
    navi("/consentform");
  };

  const windowWidth = window.innerWidth;

  const itemWidth = windowWidth <= 425 ? "100%" : "auto";
  const ladyImgRef = useRef(null);
  const ladyTextRef = useRef(null);
  const yogaImgRef = useRef(null);
  const yougaImgTextRef = useRef(null);
  const life2Img = useRef(null);
  const life2ImgText = useRef(null);
  const bannerBelowRow = useRef(null);
  const counsellingMain = useRef(null);
  const counsellingCarousel = useRef(null);
  const misunderstandingImg = useRef(null);
  const misunderstandingContent = useRef(null);
  const familyJoyImg = useRef(null);
  const familyJoyContent = useRef(null);
  // const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.2 }
    );

    if (ladyImgRef.current) {
      observer.observe(ladyImgRef.current);
    }
    if (ladyTextRef.current) {
      observer.observe(ladyTextRef.current);
    }

    if (yogaImgRef.current) {
      observer.observe(yogaImgRef.current);
    }
    if (yougaImgTextRef.current) {
      observer.observe(yougaImgTextRef.current);
    }
    if (life2Img.current) {
      observer.observe(life2Img.current);
    }
    if (life2ImgText.current) {
      observer.observe(life2ImgText.current);
    }
    if (bannerBelowRow.current) {
      observer.observe(bannerBelowRow.current);
    }
    if (counsellingMain.current) {
      observer.observe(counsellingMain.current);
    }
    if (counsellingCarousel.current) {
      observer.observe(counsellingCarousel.current);
    }

    if (familyJoyImg.current) {
      observer.observe(familyJoyImg.current);
    }
    if (familyJoyContent.current) {
      observer.observe(familyJoyContent.current);
    }
    if (misunderstandingImg.current) {
      observer.observe(misunderstandingImg.current);
    }
    if (misunderstandingContent.current) {
      observer.observe(misunderstandingContent.current);
    }

    return () => {
      if (ladyImgRef.current) {
        observer.unobserve(ladyImgRef.current);
      }
      if (ladyTextRef.current) {
        observer.unobserve(ladyTextRef.current);
      }
      if (yogaImgRef.current) {
        observer.unobserve(yogaImgRef.current);
      }
      if (yougaImgTextRef.current) {
        observer.unobserve(yougaImgTextRef.current);
      }
      if (life2Img.current) {
        observer.unobserve(life2Img.current);
      }
      if (life2ImgText.current) {
        observer.unobserve(life2ImgText.current);
      }
      if (bannerBelowRow.current) {
        observer.unobserve(bannerBelowRow.current);
      }
      if (counsellingMain.current) {
        observer.unobserve(counsellingMain.current);
      }

      if (counsellingCarousel.current) {
        observer.unobserve(counsellingCarousel.current);
      }

      if (misunderstandingImg.current) {
        observer.unobserve(misunderstandingImg.current);
      }
      if (misunderstandingContent.current) {
        observer.unobserve(misunderstandingContent.current);
      }
      if (familyJoyImg.current) {
        observer.unobserve(familyJoyImg.current);
      }
      if (familyJoyContent.current) {
        observer.unobserve(familyJoyContent.current);
      }
    };
  }, []);

  // const services = [
  //   {
  //     id: 1,
  //     img1: couple,
  //     img2: counselling1,
  //     title: "Couples Counselling",
  //   },
  //   {
  //     id: 2,
  //     img1: family,
  //     img2: counselling2,
  //     title: "Family Therapy",
  //   },
  //   {
  //     id: 3,
  //     img1: marital,
  //     img2: counselling3,
  //     title: "Marital Relationship Enhancement",
  //   },
  //   {
  //     id: 4,
  //     img1: communication,
  //     img2: counselling4,
  //     title: "Communication Skills Development",
  //   },
  //   {
  //     id: 5,
  //     img1: conflict,
  //     img2: counselling5,
  //     title: "Conflict Resolution Strategies",
  //   },
  //   {
  //     id: 6,
  //     img1: emotional,
  //     img2: counselling6,
  //     title: "Emotional Well-being Support",
  //   },
  // ];

  // const showPrev = () => {
  //   if (currentIndex > 0) {
  //     setCurrentIndex(currentIndex - 1);
  //   }
  // };

  // const showNext = () => {
  //   if (currentIndex < services.length - 3) {
  //     setCurrentIndex(currentIndex + 1);
  //   }
  // };

  return (
    <div>
      <div>
        <div className="HomePage-main-img-div">
          <img
            className="homepage-main-img"
            src={BannerImage}
            alt="BannerImage"
          />
        </div>
        <div className="homepage-main-logo-div">
          <img
            className="homepage-logo"
            src={logo}
            onClick={() => navToHome("/")}
          ></img>
        </div>
        <div className="homepage-main-tend-text ">
          <p className="mb-0 homepage-tend-text">TEND TO MEND</p>
          <p className="homepage-main-counselling-text">COUNSELLING</p>
        </div>

        <p className="Homepage-main-lalitha-text">Dr. Lalitha Ragul</p>
        <p className="Homepage-main-partner-text">
          Your Partner in Emotional Wellness
        </p>
        <button
          className="homepage-main-book-button"
          onClick={handlenavToConsent}
        >
          BOOK ONLINE
        </button>
        <img className="homepage-video-sun-image" src={sunvideo}></img>
        <span className="homepage-main-watch-text">WATCH VIDEO</span>
      </div>

      <div
        className="row homepage-banner-img-below-content"
        ref={bannerBelowRow}
      >
        <div className="col-md-12 col-lg-2 hompage-banner-below-col1">
          <img
            className="homepage-half-sun-left"
            src={sun}
            alt="sun-half"
          ></img>
        </div>
        <div className="col-md-12 col-lg-9 hompage-banner-below-col2 d-flex flex-column justify-content-center">
          <span className="homepage-banner-below-col-span">
            Are you navigating the complexities of family
          </span>
          <span className="homepage-banner-below-col-span">
            dynamics or facing challenges in your marital relationship?
          </span>
          <sapn className="homepage-banner-below-col-gold-span mt-2">
            <span className="homepage-dr-lalitha-name-span">
              Dr. Lalitha Ragul,
            </span>{" "}
            a distinguished counselling
          </sapn>
          <sapn className="homepage-banner-below-col-gold-span">
            Psychologist and expert in family and marital relationships.
          </sapn>
        </div>

        <img
          className="homepage-sun-hand-img"
          src={sunimage}
          alt="sunimage"
        ></img>
      </div>
      <div>
        <div className="row">
          <div
            className="homepage-1stcol-lady-img  col-lg-6 col-md-12"
            style={{ position: "relative" }}
            ref={ladyImgRef}
          >
            <img className="homepage-lady-img" src={lady1} alt="lady"></img>
            <div className="d-flex flex-column align-items-center homepage-years-div">
              <span className="homepage-5years-text-span">5+</span>
              <span className="homepage-years-text-span">YEARS</span>
            </div>
          </div>
          <div
            className="col-lg-5 col-md-12 hompage-right-col-lady-img-text"
            ref={ladyTextRef}
          >
            <div className="d-flex flex-column justify-content-center homepage-about-lady-content-div">
              <span className="homepage-right-col-lady-about">ABOUT US</span>
              <span className="homepage-right-col-lady-name">
                Dr. Lalitha Ragul
              </span>
              <span className="homepage-right-col-lady-content">
                {" "}
                With a wealth of experience and a compassionate approach,{" "}
                <span className="homepage-dr-lalitha-span ">
                  Dr. Lalitha Ragul
                </span>{" "}
                is dedicated to helping individuals and families build strong,
                healthy connections. Her expertise lies in understanding the
                intricacies of family dynamics and providing personalized
                guidance to enhance marital relationships.
              </span>
              <div>
                <img
                  className="homepage-right-col-lady-sign mt-2"
                  src={sign}
                  alt="sign-Image"
                ></img>
              </div>
              <sapn className="homepage-right-col-lady-signname">
                (Dr Lalitha Ragul)
              </sapn>
            </div>
          </div>
        </div>
      </div>
      <div className="Homepage-1st-div-bg">
        <div className="row">
          <div className="col-lg-6 col-md-12 homepage-navigating-life-col d-flex">
            <div
              className="d-flex flex-column homepage-navigating-life-div"
              ref={yougaImgTextRef}
            >
              <span className="homepage-navigating-life-div-span">
                Navigating Life's Journey Together
              </span>
              <span className="homepage-navigating-life-content-span">
                Life's journey is filled with twists and turns, and{" "}
                <span className="homepage-dr-lalitha-span">
                  DR. Lalitha Ragul
                </span>{" "}
                is here to guide you through the emotional terrain. Her
                counselling services are tailored to address a range of issues,
                including communication breakdowns, conflict resoulution, and
                emotional well-being within the family unit.
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="homepage-navigating-life-right-div">
              <img
                className="homepage-navigating-life-yoga-img"
                src={yoga}
                alt="yoga"
                ref={yogaImgRef}
              ></img>
            </div>
          </div>
        </div>
        <div className="row homepage-navigating-life2-row">
          <div className="col-lg-6 col-md-12">
            <div className="homepage-navigating-life-life2-div">
              <img
                className="homepage-navigating-life-lefe2-img"
                src={life2}
                alt="life2"
                ref={life2Img}
              ></img>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 homepage-navigating-life2-col d-flex">
            <div
              className="d-flex flex-column homepage-navigating-life2-div"
              ref={life2ImgText}
            >
              <span className="homepage-navigating-life2-div-span">
                Specialization in Marital Relationships
              </span>
              <span className="homepage-navigating-life-content-span">
                As a specialist in family and marital relationships,{" "}
                <span className="homepage-dr-lalitha-span ">
                  {" "}
                  Dr. Lalitha Ragul{" "}
                </span>
                brings a unique blend of clinical knowledge and practical
                insights. Whether you're a couple seeking to strengthen your
                bond or an individual grappling with relationship challenges,
                her therapeutic interventions foster understanding and
                connection.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="homepage-counselling-bg">
        <div
          className="d-flex justify-content-center homepage-counselling-service-main-div"
          ref={counsellingMain}
        >
          <div className="d-flex flex-column align-items-center">
            <span className="homepage-counselling-text-span">
              Counselling Services Offered
            </span>
            <span className="homepage-certain-text-span">
              Certain financial information and data are available upon request.
              Interested parties are directed to read all the information
              carefully.
            </span>
          </div>
        </div>
        <div
          className="d-flex justify-content-center mt-5 homepage-counselling-carousel-main-div"
          ref={counsellingCarousel}
        >
          <div className="">
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={false}
              itemClass={`carousel-item-custom ${
                itemWidth === "100%" ? "mobile-item-width" : ""
              }`}
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 3,
                  partialVisibilityGutter: 50,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 0,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              <div className="homepage-1st-col-counselling-service d-flex justify-content-center">
                <div className="d-flex flex-column hompage-counselling-cards">
                  <img
                    src={couple}
                    className="homepage-couple-img"
                    alt="couple"
                  ></img>
                  <img
                    src={counselling1}
                    className="homepage-oct-img"
                    alt="counselling-family"
                  ></img>
                  <span className="hompage-couples-counselling-text-span">
                    Couple Counselling
                  </span>
                  <div className="d-flex justify-content-center">
                    <span
                      className="homepage-get-started-text-span"
                      onClick={handlenavToConsent}
                    >
                      GET STARTED
                    </span>
                  </div>
                </div>
              </div>
              <div className="homepage-1st-col-counselling-service d-flex justify-content-center">
                <div className="d-flex flex-column hompage-counselling-cards">
                  <img
                    src={family}
                    className="homepage-couple-img"
                    alt="family"
                  ></img>
                  <img
                    src={counselling2}
                    className="homepage-oct-img"
                    alt="family"
                  ></img>
                  <span className="hompage-couples-counselling-text-span">
                    Family Therapy
                  </span>
                  <div className="d-flex justify-content-center">
                    <span
                      className="homepage-get-started-text-span"
                      onClick={handlenavToConsent}
                    >
                      GET STARTED
                    </span>
                  </div>
                </div>
              </div>
              <div className="homepage-1st-col-counselling-service d-flex justify-content-center">
                <div className="d-flex flex-column hompage-counselling-cards">
                  <img
                    src={marital}
                    className="homepage-couple-img"
                    alt="marital"
                  ></img>
                  <img
                    src={counselling3}
                    className="homepage-last-three-oct-image"
                    alt="relationship"
                  ></img>
                  <span className="hompage-couples-counselling-text-span-last-three">
                    Marital Relationship Enhancement
                  </span>
                  <div className="d-flex justify-content-center">
                    <span
                      className="homepage-get-started-text-span"
                      onClick={handlenavToConsent}
                    >
                      GET STARTED
                    </span>
                  </div>
                </div>
              </div>
              <div className="homepage-1st-col-counselling-service d-flex justify-content-center">
                <div className="d-flex flex-column hompage-counselling-cards">
                  <img
                    src={communication}
                    className="homepage-couple-img"
                    alt="communication"
                  ></img>
                  <img
                    src={counselling4}
                    className="homepage-last-three-oct-image"
                    alt="communication"
                  ></img>
                  <span className="hompage-couples-counselling-text-span-last-three">
                    Communication Skills Development
                  </span>
                  <div className="d-flex justify-content-center">
                    <span
                      className="homepage-get-started-text-span"
                      onClick={handlenavToConsent}
                    >
                      GET STARTED
                    </span>
                  </div>
                </div>
              </div>
              <div className="homepage-1st-col-counselling-service d-flex justify-content-center">
                <div className="d-flex flex-column hompage-counselling-cards">
                  <img
                    src={conflict}
                    className="homepage-couple-img"
                    alt="conflict"
                  ></img>
                  <img
                    src={counselling5}
                    className="homepage-last-three-oct-image"
                    alt="conflict"
                  ></img>
                  <span className="hompage-couples-counselling-text-span-last-three">
                    Conflict Resolution Strategies
                  </span>
                  <div className="d-flex justify-content-center">
                    <span
                      className="homepage-get-started-text-span"
                      onClick={handlenavToConsent}
                    >
                      GET STARTED
                    </span>
                  </div>
                </div>
              </div>
              <div className="homepage-1st-col-counselling-service d-flex justify-content-center">
                <div className="d-flex flex-column hompage-counselling-cards">
                  <img
                    src={emotional}
                    className="homepage-couple-img"
                    alt="emotional"
                  ></img>
                  <img
                    src={counselling6}
                    className="homepage-last-three-oct-image"
                    alt="emotion"
                  ></img>

                  <span className="hompage-couples-counselling-text-span-last-three">
                    Emotional Well-being Support
                  </span>

                  <div className="d-flex justify-content-center">
                    <span
                      className="homepage-get-started-text-span"
                      onClick={handlenavToConsent}
                    >
                      GET STARTED
                    </span>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
          {/* <div className="slider-container">
      <button className="slider-button" onClick={showPrev}>
      <FaCircleChevronLeft className="homepage-slider-left-right-icons"/>
      </button>
      <div className="slider-content">
        {services.slice(currentIndex, currentIndex + 3).map((service) => (
          <div key={service.id} className="slider-item">
            <div className="d-flex flex-column hompage-counselling-cards">
              <img src={service.img1} className="homepage-couple-img" alt={service.title} />
              <img src={service.img2} className="homepage-oct-img" alt={service.title} />
              <span className="hompage-couples-counselling-text-span">
                {service.title}
              </span>
              <div className="d-flex justify-content-center">
                <span className="homepage-get-started-text-span">
                  GET STARTED
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button className="slider-button" onClick={showNext}>
      <FaCircleChevronRight className="homepage-slider-left-right-icons" /> 
      </button>
    </div> */}
        </div>
      </div>
      <div className="Homepage-1st-div-bg3">
        <div className="row">
          <div className="col-lg-6 col-md-12 homepage-life3-row2-col1-below-counselling d-flex">
            <div
              className="d-flex flex-column homepage-life3-row2-div"
              ref={familyJoyContent}
            >
              <span className="homepage-life3-row2-div-span">
                Holistic and Person-Centered Approach
              </span>
              <span className="homepage-navigating-life-content-span">
                <span className="homepage-dr-lalitha-span">
                  Dr. Lalitha Ragul{" "}
                </span>
                adopts a holistic and person-centered approach, recognizing the
                unique needs of each individual and family. Her warm and
                empathetic demeanour creates a safe space for open dialogue and
                self-discovery.{" "}
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="homepage-navigating-life-right-div">
              <img
                className="homepage-life3-row2-misunderstanding-img"
                src={misunderstanding}
                alt="yoga"
                ref={familyJoyImg}
              ></img>
            </div>
          </div>
        </div>
        <div className="row homepage-navigating-life2-row">
          <div className="col-lg-6 col-md-12">
            <div className="homepage-life3-row2-img-div">
              <img
                className="homepage-life3-row-family-img"
                src={family2}
                alt="life2"
                ref={misunderstandingImg}
              ></img>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 homepage-navigating-life2-col d-flex">
            <div
              className="d-flex flex-column homepage-life3-row4-family-div"
              ref={misunderstandingContent}
            >
              <span className="homepage-navigating-life2-div-span">
                Take the First Step
              </span>
              <span className="homepage-navigating-life-content-span">
                Embark on a journey towards emotional well-being and stronger
                relationships. Contact{" "}
                <span className="homepage-dr-lalitha-span">
                  <br></br>Dr. Lalitha Ragul
                </span>{" "}
                today to schedule a confidential consultation. Together, let's
                build a foundation for lasting happiness and fulfillment.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-5 col-md-12  homepage-bottom-tend-to-mend-1st-col">
          <div className="d-flex flex-column homepage-bottom-tend-to-mend-logo-div">
            <span className="homepage-bottom-benefits-text-span">
              Benefits of Counselling at
            </span>
            <span className="homepage-bottom-tend-to-mend-text">
              TEND TO MEND
            </span>
            <img src={logo}></img>
          </div>
        </div>
        <div className="col-lg-7 col-md-12 homepage-bottom-benefitsofcounselling-col2">
          <div className="d-flex flex-column homepage-bottom-tend-to-mend-col2">
            <span className="homepage-bottom-lalitha-text">
              Dr. Lalitha Ragul,
            </span>
            <span className="homepage-bottom-counselling-text">
              Counselling Psychologist,
            </span>
            <span className="homepage-bottom-counselling-text">
              Specialist in Marital, Family & Child Counselling.
            </span>
            <div className="d-flex homepage-bottom-email-div">
              <span>
                <MdMail className="homepage-bottom-email-logo" />
              </span>
              <span className="homepage-bottom-mail-text">
                lalitharagul88@gmail.com
              </span>
            </div>
            <div className="d-flex homepage-bottom-email-div">
              <span>
                <BiSolidPhoneCall className="homepage-bottom-email-logo" />
              </span>
              <span className="homepage-bottom-mail-text">+91 9003681043</span>
            </div>
          </div>
          <div className="homepage-bottom-timing-whole-div">
            <div className="d-flex homepage-bottom-timing-div">
              {" "}
              <img className="homepage-bottom-timing-img" src={time}></img>
              <div className="d-flex flex-column homepage-timing-col-2">
                <span className="homepage-bottom-timing-text">Timings</span>
                <div>
                  <span className="homepage-bottom-montosat-text">
                    (Mon to Sat)
                  </span>{" "}
                  <span className="hompage-bottom-timing-number">
                    - 6 pm to 9 pm
                  </span>
                </div>
                <div>
                  <span className="homepage-bottom-montosat-text">(Sun)</span>
                  <span className="hompage-bottom-timing-number">
                    - 10am to 5pm
                  </span>
                </div>
              </div>
            </div>
          </div>
          <img className="homepage-bottom-sun1-image" src={sun1}></img>
        </div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <span className="homepage-bottom-modes-of-counselling-text">
          Modes for counselling
        </span>
        <img className="homepage-bottom-arrow-img" src={arrow}></img>
      </div>
      <div className="d-flex justify-content-center">
        {" "}
        <BiSolidDownArrowAlt className="homepage-bottom-mobile-arrrow" />
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-12">
          <div className="d-flex justify-content-end homepage-bottom-video-main-div">
            <div className="homepage-bottom-video-div">
              <span className="homepage-bottom-video-icon-span">
                <FaVideo className="homepage-bottom-video-icon" />
              </span>
              <span className="homepage-bottom-video-call-text">
                Video Call
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <BiSolidDownArrowAlt className="homepage-bottom-mobile-arrrow" />
          </div>
        </div>
        <div className="col-lg-4 col-md-12 pe-0">
          <div className="d-flex justify-content-center">
            <div className="homepage-bottom-voice-div">
              <span className="homepage-bottom-video-icon-span">
                <BiSolidPhoneCall className="homepage-bottom-video-icon" />
              </span>
              <span className="homepage-bottom-video-call-text">
                Voice Call
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            {" "}
            <BiSolidDownArrowAlt className="homepage-bottom-mobile-arrrow" />
          </div>
        </div>

        <div className="col-lg-4 col-md-12">
          <div className="d-flex justify-content-start homepage-bottom-chat-main-div">
            <div className="homepage-bottom-chat-div">
              <span className="homepage-bottom-video-icon-span">
                <IoIosChatboxes className="homepage-bottom-video-icon" />
              </span>
              <span className="homepage-bottom-chat-text">Chat</span>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default HomePage;
