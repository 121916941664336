// /PaymentGateway.jsx
import React, { useState } from "react";
import "./PaymentGateway.css";
import config from "./config";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const { apiBaseUrl } = config;

const PaymentGateway = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { bookingId, userDetails } = location.state || {};
  const [sessionType, setSessionType] = useState("individual");
  const [isLoading, setIsLoading] = useState(false); // Loading state
  // const amount = sessionType === "couple" ? 1500 : 1200;
  const amount = sessionType === "couple" ? 2 : 1;

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    const isScriptLoaded = await loadRazorpayScript();
    if (!isScriptLoaded) {
      alert("Failed to load Razorpay SDK. Please try again later.");
      return;
    }

    try {
      setIsLoading(true);
      // Create Razorpay order
      const { data } = await axios.post(
        `${apiBaseUrl}/api/payment/create-order`,
        {
          amount,
          bookingId,
        }
      );

      const { orderId } = data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: amount * 100,
        currency: "INR",
        name: "Tend to Mend Counselling",
        description: "Payment for counselling session",
        order_id: orderId,
        prefill: {
          name: userDetails.name,
          email: userDetails.email,
          contact: userDetails.mobile,
        },
        theme: {
          color: "#4e361f",
        },

        modal: {
          ondismiss: function () {
            console.log("User closed the Razorpay popup");
            navigate("/");
          },
        },

        handler: async (response) => {
          try {
            // Verify payment
            // const verificationResponse = await axios.post(
            //   `${apiBaseUrl}/api/payment/verify-payment`,
            //   {
            //     paymentId: response.razorpay_payment_id,
            //     orderId: response.razorpay_order_id,
            //     signature: response.razorpay_signature,
            //     bookingId,
            //     amount,
            //   }
            // );

            // if (!verificationResponse.data.success) {
            //   alert("Payment verification failed.");
            //   return;
            // }

            // Confirm payment
            const confirmResponse = await axios.post(
              `${apiBaseUrl}/api/payment/confirm-payment`,
              {
                paymentId: response.razorpay_payment_id,
                orderId: response.razorpay_order_id,
                signature: response.razorpay_signature,
                bookingId,
                amount,
              }
            );

            // console.log("Confirm Response:", confirmResponse.data);
            console.log("Full Confirm Response:", confirmResponse);

            //   if (confirmResponse.data.success) {
            //     alert("Payment successful and email sent!");
            //     navigate("/confirmation", { state: { bookingId, paymentStatus: "Paid" } });
            //   } else {
            //     alert("Payment confirmed but email could not be sent. Please contact support.");
            //   }
            // } catch (error) {
            //   alert("An error occurred while verifying or confirming payment. Please try again.");
            // }

            if (
              confirmResponse.data.success === true ||
              confirmResponse.status === 200
            ) {
              console.log(
                "Confirm Response Success:",
                confirmResponse.data.success
              );
              navigate("/confirmation", {
                state: { bookingId, paymentStatus: "Paid" },
              });
            } else {
              alert(
                "Payment confirmed but email could not be sent. Please contact support."
              );
              setIsLoading(false); // Stop loading
            }
          } catch (error) {
            console.error(
              "Error in payment verification or confirmation:",
              error
            );
            alert(
              "An error occurred while verifying or confirming payment. Please try again."
            );
            setIsLoading(false); // Stop loading
          }
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      alert("Failed to initiate payment.");
      setIsLoading(false); // Stop loading
    }
  };
  if (isLoading) {
    return (
      <div className="captcha-whole-div">
        <div className="qr-page-main-div">
          <h2 className="payment-below-payment-text">Processing Payment</h2>
          <p className="captcha-www-text">
            Please wait while we process your payment. Do not refresh or close
            this window.
          </p>

          <div className="loading-animation">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="captcha-whole-div">
      <div className="qr-page-main-div">
        <h2 className="payment-below-payment-text">Payment Details</h2>
        <p className="captcha-www-text">
          Please review your details and proceed to payment.
        </p>

        <div className="qrpage-scan-bank">
          {/* User Information */}
          <div className="qr-img-div">
            <p className="qr-page-accountdetail-text">User Information</p>
            <p className="qrpage-account-texts">
              <strong>Name:</strong> {userDetails.name}
            </p>
            <p className="qrpage-account-texts">
              <strong>Parent/Guardian:</strong> {userDetails.parent}
            </p>
            <p className="qrpage-account-texts">
              <strong>Email:</strong> {userDetails.email}
            </p>
            <p className="qrpage-account-texts">
              <strong>Phone:</strong> {userDetails.mobile}
            </p>
            <p className="qrpage-account-texts">
              <strong>Address:</strong> {userDetails.address}
            </p>
          </div>

          {/* Session Selection */}
          <div className="qrpage-session-detail">
            <p className="qr-page-accountdetail-text">Select Session Type</p>
            <select
              className="qrpage-session-dropdown"
              value={sessionType}
              onChange={(e) => setSessionType(e.target.value)}
            >
              <option value="individual">Individual Counselling - ₹1200</option>
              <option value="couple">Couple Counselling - ₹1500</option>
            </select>
          </div>

          {/* Amount to Pay */}
          <div className="qrpage-bank-detail">
            <p className="qr-page-accountdetail-text">Amount to Pay</p>
            <p className="qrpage-account-texts">
              <strong>₹{amount}/-</strong>
            </p>
          </div>
        </div>

        <button className="qr-home-button mt-2" onClick={handlePayment}>
          Proceed to Pay
        </button>
      </div>
    </div>
  );
};

export default PaymentGateway;
