// useBase64Image.js
import { useState, useEffect } from "react";

function useBase64Image(imageUrl) {
  const [imageData, setImageData] = useState({
    base64: null,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!imageUrl) return;

    const fetchImageAsBase64 = async () => {
      try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64 = reader.result;
  
            // Create an Image object to get dimensions
            const img = new Image();
            img.onload = () => {
              setImageData({
                base64: base64,
                width: img.width,
                height: img.height,
              });
            };
            img.onerror = (err) => {
              console.error("Error loading image for dimensions:", err);
            };
            img.src = base64;
          };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error loading image as Base64:", error);
      }
    };

    fetchImageAsBase64();
  }, [imageUrl]);

  return imageData;
}

export default useBase64Image;
