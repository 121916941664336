// /Confirmation.jsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import useBase64Image from "./useBase64Image";
import ttmpdfbanner from "./tmimages/ttm-pdf-Banner.png";
import ttmpdffooter from "./tmimages/ttm-pdf-footer.jpg";
import config from "./config";

const { apiBaseUrl } = config;

const Confirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { bookingId } = location.state || {};

  const [userDetails, setUserDetails] = useState(null);
  // const [headerImage, setHeaderImage] = useState(null);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  // const [footerImage, setFooterImage] = useState(null);
  // const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const headerData = useBase64Image(ttmpdfbanner);
  const footerData = useBase64Image(ttmpdffooter);

  // Load images on component mount
  // useEffect(() => {
  //   // Load header image
  //   const headerImg = new Image();
  //   headerImg.src = ttmpdfbanner;
  //   headerImg.onload = () => setHeaderImage(headerImg);

  //   // Load footer image
  //   const footerImg = new Image();
  //   footerImg.src = ttmpdffooter;
  //   footerImg.onload = () => setFooterImage(footerImg);
  // }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { data } = await axios.get(
          `${apiBaseUrl}/api/users/details/${bookingId}`
        );
        if (data.success) {
          setUserDetails(data.user);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        navigate("/");
      }
    };

    if (bookingId) {
      fetchUserDetails();
    } else {
      navigate("/");
    }
  }, [bookingId, navigate]);

  // Handle checked_items parsing with fallback
  const getSessionType = () => {
    try {
      if (userDetails?.checked_items) {
        const items = JSON.parse(userDetails.checked_items);
        return Array.isArray(items) ? items.join(", ") : "Invalid data";
      }
      return "N/A";
    } catch (error) {
      console.error("Error parsing checked_items:", error);
      return "Invalid data";
    }
  };

  // Format date
  const getFormattedDate = () => {
    if (!userDetails?.booking_date) return "Invalid Date";
    const date = new Date(userDetails.booking_date);
    return !isNaN(date)
      ? date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      : "Invalid Date";
  };

  // Format currency
  const formatCurrency = (amount) => {
    if (!amount) return "Rs 0"; // Use the rupee symbol directly
    return `Rs ${new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount)}`;
  };

  const generatePDF = () => {
    // Only generate if images (Base64) are ready
    if (!headerData.base64 || !footerData.base64) {
      alert("Images not loaded yet. Please wait a moment and try again.");
      return;
    }

    setIsGeneratingPDF(true);
    try {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Set background
      doc.setFillColor(250, 250, 250);
      doc.rect(0, 0, 210, 297, "F");

      // Add header image
      // Provide the base64 string, specify "PNG" or "JPEG" matching the actual file
      const desiredHeaderWidth = 190; // in mm
      const headerAspectRatio = headerData.width / headerData.height;
      const headerHeight = desiredHeaderWidth / headerAspectRatio;
      doc.addImage(
        headerData.base64,
        "PNG",
        10,
        10,
        desiredHeaderWidth,
        headerHeight
      );

      // Add footer image
      const desiredFooterWidth = 190; // in mm
      const footerAspectRatio = footerData.width / footerData.height;
      const footerHeight = desiredFooterWidth / footerAspectRatio;
      const pageHeight = doc.internal.pageSize.getHeight();
      doc.addImage(
        footerData.base64,
        "JPEG", // Assuming footer is a JPEG; adjust if different
        10,
        pageHeight - footerHeight - 10, // 10mm margin from bottom
        desiredFooterWidth,
        footerHeight
      );

      // Content
      const startY = 10 + headerHeight + 10; // Header position + height + spacing
      const leftMargin = 20;
      const labelWidth = 40;
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text("Booking and Payment Confirmation", leftMargin, startY);

      doc.setFontSize(12);
      let currentY = startY + 15;
      const lineHeight = 10;

      const addField = (label, value) => {
        doc.setFont("helvetica", "bold");
        doc.text(`${label}:`, leftMargin, currentY);
        doc.setFont("helvetica", "normal");
        doc.text(value || "N/A", leftMargin + labelWidth, currentY);
        currentY += lineHeight;
      };

      // Now add your user details lines
      addField("Name", userDetails.name);
      addField("Parent/Guardian", userDetails.parent);
      addField("Email", userDetails.email);
      addField("Phone", userDetails.mobile);
      addField("Address", userDetails.address);
      addField("Session Type", getSessionType());
      addField("Booking Date", getFormattedDate());
      addField("Time Slot", userDetails.booking_time_slot);
      addField("Payment ID", userDetails.payment_id);
      addField("Amount Paid", formatCurrency(userDetails.amount_paid));

      // Finally, save
      doc.save("booking-confirmation.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsGeneratingPDF(false);
    }
  };

  if (!userDetails) {
    return <p>Loading user details...</p>;
  }

  return (
    <div className="captcha-whole-div">
      <div className="qr-page-main-div">
        <h2 className="payment-below-payment-text">Payment Confirmation</h2>
        <p className="captcha-www-text">
          Here are your booking and payment details:
        </p>

        <div className="qrpage-scan-bank">
          <div className="qr-img-div">
            <p className="qr-page-accountdetail-text">User Information</p>
            <p className="qrpage-account-texts">
              <strong>Name:</strong> {userDetails.name}
            </p>
            <p className="qrpage-account-texts">
              <strong>Parent/Guardian:</strong> {userDetails.parent || "N/A"}
            </p>
            <p className="qrpage-account-texts">
              <strong>Email:</strong> {userDetails.email}
            </p>
            <p className="qrpage-account-texts">
              <strong>Phone:</strong> {userDetails.mobile}
            </p>
            <p className="qrpage-account-texts">
              <strong>Address:</strong> {userDetails.address || "N/A"}
            </p>
            <p className="qrpage-account-texts">
              <strong>Session Type:</strong> {getSessionType()}
            </p>
            <p className="qrpage-account-texts">
              <strong>Booking Date:</strong> {getFormattedDate()}
            </p>
            <p className="qrpage-account-texts">
              <strong>Time Slot:</strong>{" "}
              {userDetails.booking_time_slot || "N/A"}
            </p>
          </div>

          <div className="qrpage-bank-detail">
            <p className="qr-page-accountdetail-text">Payment Details</p>
            <p className="qrpage-account-texts">
              <strong>Payment ID:</strong> {userDetails.payment_id || "N/A"}
            </p>
            <p className="qrpage-account-texts">
              <strong>Payment Status:</strong>{" "}
              {userDetails.payment_status || "N/A"}
            </p>
            <p className="qrpage-account-texts">
              <strong>Amount Paid:</strong>{" "}
              {formatCurrency(userDetails.amount_paid)}
            </p>
          </div>
        </div>

        <button
          className="qr-home-button mt-2 mr-1"
          onClick={() => navigate("/")}
        >
          Go to Home
        </button>
        <button
          className="qr-home-button mt-2 ml-1 downloadpdf-btn"
          onClick={generatePDF}
          disabled={isGeneratingPDF}
        >
          {isGeneratingPDF ? "Generating PDF..." : "Download PDF"}
        </button>
      </div>
    </div>
  );
};

export default Confirmation;
